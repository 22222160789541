<template>
  <div>
    <v-btn
      v-for="filtro in Object.keys(estadosFiltros)"
      :key="filtro"
      small
      text
      :class="{ 'ma-1': true, 'v-btn--active': estadosFiltros[filtro] }"
      :color="getSituacionIcon(filtro).color"
      @click.stop="clicked().then(t => clickHandler(t, filtro))"
      :disabled="items.filter(imp => imp[filterKey] == filtro).length == 0"
    >
      <v-icon left>{{ getSituacionIcon(filtro).icon }}</v-icon>
      {{ items.filter(imp => imp[filterKey] == filtro).length }} {{ filtro }}
    </v-btn>
  </div>
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js"

export default {
  props: {
    value: Object,
    items: Array,
    filterKey: String
  },
  computed: {
    estadosFiltros: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    }
  },
  data() {
    return {
      click: null
    }
  },
  methods: {
    getSituacionIcon,
    clicked() {
      return new Promise ((resolve, reject) => {
        if (this.click) {
          clearTimeout(this.click)
          resolve('double')
        }
        this.click = setTimeout(() => {
         this.click = undefined
         resolve('single')
        }, 200)
      })
    },
    clickHandler(clickType, filtro) {
      if (clickType == 'double') {
        this.estadosFiltros[filtro] = !this.estadosFiltros[filtro]
        this.$emit('filter-by', this.estadosFiltros[filtro] ? filtro : null )
      } else if (clickType == 'single') {
        if ( this.estadosFiltros[filtro] )
          this.$emit('filter-by', null )
        this.estadosFiltros[filtro] = !this.estadosFiltros[filtro]
      }
    }
  }
}
</script>